import React, { JSX, ReactNode } from 'react';
import { Box, Container } from '@mui/material';

import { usePageTitle } from '../../modules/hooks';
import { t } from '../../modules/utils';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar/Sidebar';
import { useLayoutDefaultStyles } from './useLayoutDefaultStyles';

export interface ILayoutDefaultProps {
  children?: ReactNode;
  title?: string;
}

const SIDEBAR_DISPLAY = false;

export function LayoutDefault({
  children,
  title,
}: ILayoutDefaultProps): JSX.Element {
  const classes = useLayoutDefaultStyles();
  usePageTitle({ projectName: t('project-name'), pageTitle: title ?? '' });

  return (
    <Box className={classes.root}>
      <Header />
      <Container component="main" className={classes.main}>
        <Box className={classes.mainContent}>{children}</Box>
        {SIDEBAR_DISPLAY && <Sidebar />}
      </Container>
    </Box>
  );
}
