export enum MrpcBlockchainType {
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
}

export enum ServiceStatus {
  DEGRADED = 0,
  OUTAGE = 1,
  HEALTHY = 2,
  NOTFOUND = 3,
}

export const SUCCESS_STATUSES = [ServiceStatus.HEALTHY, ServiceStatus.NOTFOUND];

export enum Colors {
  RED = 'red', // degraded
  ORANGE = 'orange', // outage
  GREEN = 'green', // healthy
  GREY = 'grey', // not found
}

export const SUCCESS_COLORS = [Colors.GREEN, Colors.GREY];
