import React from 'react';
import { CrossNavigation, LogoType } from '@ankr.com/cross-navigation';
import { Box, Container } from '@mui/material';

import '@ankr.com/cross-navigation/style.css';

import { useThemeMode } from '../../modules/themes';
import { useHeader } from './useHeader';
import { useHeaderStyles } from './useHeaderStyles';

export function Header() {
  const { triggerTouched } = useHeader();

  const { classes, cx } = useHeaderStyles();
  const themeMode = useThemeMode();

  return (
    <Box
      component="header"
      className={cx(
        classes.appBar,
        triggerTouched ? classes.appBarScrolled : '',
      )}
    >
      <Container className={classes.container}>
        <Box className={classes.logoWrap}>
          <CrossNavigation theme={themeMode} logoType={LogoType.STATUS} />
        </Box>
      </Container>
    </Box>
  );
}
