import { makeStyles } from 'tss-react/mui';

export const useBlockChainStyles = makeStyles()(theme => ({
  cardWrapper: {
    background: '#FFFFFF',
    borderRadius: '16px',
    padding: `${theme.spacing(6, 8)}`,
    minHeight: 132,

    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(5, 3)}`,
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: `${theme.spacing(3)}`,

    [theme.breakpoints.down('sm')]: {
      alignItems: 'unset',
      flexDirection: 'column',
    },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(2)}`,

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(5),
      position: 'relative',

      '&:after': {
        position: 'absolute',
        content: '""',
        width: '100%',
        height: 1,
        left: 0,
        bottom: 0,
        backgroundColor: '#F2F5FA',
      },
    },
  },
  percentage: {
    fontSize: '20px',
  },
  networkTitle: {
    fontSize: '20px',
  },
  labelWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(2)}`,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      gap: 'unset',
      paddingTop: theme.spacing(5),
    },
  },
  logo: {
    objectFit: 'cover',
    width: 40,
    height: 40,
  },
  healthScaleWrapper: {
    width: '100%',
    display: 'flex',
    height: 12,
    margin: theme.spacing(4, 0, 2),
    borderRadius: '3px',
    overflow: 'hidden',
    gap: `${theme.spacing(0.125)}`,
  },
  scaleUnderLabels: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    '&&': {
      fontSize: '12px',
      lineHeight: '135%',
      color: 'rgba(130, 137, 154, 1)',
    },
  },
}));
