import { TRequestPeriodUnit } from '../dashboard/screens/Dashboard/useDashboard';
import { MrpcBlockchainType, MrpcResponseType, StatsResponse } from '../types';
import { chainsIcons } from './constants';
import { countUptimePercent, getStatsByDay, getStatsByHour } from './helpers';

export const formatStatsData = (
  blockchains: MrpcResponseType[],
  dataStats: StatsResponse[],
  value: number,
  unit: TRequestPeriodUnit,
) => {
  return blockchains.map(({ id, type, name, extends: chainExtends }) => {
    const currentChainStats = dataStats.find(
      status => status.blockchain === id,
    );

    const chartData =
      unit === 'hours'
        ? getStatsByHour(value, currentChainStats?.regions)
        : getStatsByDay(value, currentChainStats?.regions);

    const icon =
      chainsIcons.find(
        icon =>
          icon.title ===
          (type === MrpcBlockchainType.TESTNET ? chainExtends : id),
      )?.link || './blockchainLogoNotFound.svg';

    return {
      id,
      type,
      name,
      uptime: countUptimePercent(chartData),
      chartData,
      icon,
    };
  });
};
