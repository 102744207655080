import { MrpcBlockchainType, ServiceStatus } from './api-response-common';

export type MrpcResponseType = {
  type: MrpcBlockchainType;
  id: string;
  name: string;
  extends: string;
};

export type StatsResponse = {
  blockchain: string;
  regions: StatsRegions;
};

export type StatsDay = {
  month: string;
  day: string;
  year: string;
  status: ServiceStatus;
  description: string;
  hour: string; // for requests with days period is always 0
};

export type StatsRegionData = {
  days: StatsDay[];
};

export enum REGION_NAME {
  WORLDWIDE = 'Worldwide',
}

export type StatsRegions = { [regionName in REGION_NAME]?: StatsRegionData };
