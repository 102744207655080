import React, { JSX } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Card, Container } from '@mui/material';

import { t } from '../../../utils';
import { usePageNotFoundStyles } from './usePageNotFoundStyles';

export function PageNotFound(): JSX.Element {
  const classes = usePageNotFoundStyles();

  return (
    <Box className={classes.wrapper}>
      <Container>
        <Card className={classes.card}>
          <Box className={classes.title}>{t('page-not-found.title')}</Box>
          <Box className={classes.hint}>{t('page-not-found.hint')}</Box>

          <Button component={Link} to="/">
            {t('page-not-found.btn')}
          </Button>
        </Card>
      </Container>
    </Box>
  );
}
