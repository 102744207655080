import React, { JSX, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { mainTheme } from '@ankr.com/ui';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { useScrollToTop } from '../../modules/hooks';
import { locales, useLocale } from '../../modules/i18n';

interface IAppBaseProps {
  children: JSX.Element;
}

export const muiCache = createCache({
  key: 'mui',
});

export function AppBase({ children }: IAppBaseProps): JSX.Element {
  const [isLocaleReady, setLocaleReady] = useState(false);

  const { locale } = useLocale();

  useEffect(() => {
    setLocaleReady(false);
    async function loadLocale(): Promise<void> {
      await intl.init({
        currentLocale: locale,
        locales,
        fallbackLocale: 'en-US',
      });

      setLocaleReady(true);
    }

    void loadLocale();
  }, [locale]);

  useScrollToTop();

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        {isLocaleReady ? children : null}
      </ThemeProvider>
    </CacheProvider>
  );
}
