import React, { JSX } from 'react';
import { Route, Routes } from 'react-router-dom';

import { LayoutDefault } from './components/LayoutDefault';
import { getDashboardRoutes } from './modules/dashboard/DashboardRoutes';
import { PageNotFound } from './modules/ui';
import { t } from './modules/utils';

export function AppRoutes(): JSX.Element {
  return (
    <Routes>
      {getDashboardRoutes()}
      <Route
        path="*"
        element={
          <LayoutDefault title={t('page-title.page-not-found')}>
            <PageNotFound />
          </LayoutDefault>
        }
      />
    </Routes>
  );
}
