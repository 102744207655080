import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { HEADER_HEIGHT, MIN_LAYOUT_WIDTH } from '../../modules/themes';

export const useLayoutDefaultStyles = makeStyles<Theme>(theme => ({
  root: {
    minWidth: MIN_LAYOUT_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    paddingTop: HEADER_HEIGHT,
  },

  main: {
    '&&': {
      display: 'flex',
      gap: `${theme.spacing(8)}`,

      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },
  },
  mainContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
