import React, { JSX } from 'react';
import { uid } from 'react-uid';
import { Select } from '@ankr.com/ui';
import {
  Box,
  Card,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material';

import { t } from '../../../utils';
import { BlockChain } from './components/BlockChain/BlockChain';
import {
  PERIOD_OPTIONS,
  REQUEST_PERIOD_DEFAULT,
  useDashboard,
} from './useDashboard';
import { useDashboardStyles } from './useDashboardStyles';

export function Dashboard(): JSX.Element {
  const {
    requestPeriod,
    dataStats,
    errorStats,
    isLoadingStats,
    isFetchingStats,
    onChangeRequestPeriod,
    isLoadingBlockchains,
  } = useDashboard();

  const { classes } = useDashboardStyles();

  const shouldRenderBlockchains =
    !isLoadingBlockchains && dataStats && dataStats?.length > 0;

  return (
    <Card component="section" className={classes.component}>
      <Box className={classes.headerWrapper}>
        <Typography
          variant="h5"
          variantMapping={{ h5: 'h2' }}
          className={classes.title}
        >
          {t('main.all-networks')}
        </Typography>
        <Select
          sx={{ padding: '2px' }}
          variant="standard"
          onChange={onChangeRequestPeriod}
          defaultValue={REQUEST_PERIOD_DEFAULT.id}
        >
          {PERIOD_OPTIONS.map(({ id, value, unit }) => (
            <MenuItem key={id} value={id} component={MenuItem}>
              {value} {unit}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={classes.cardsWrapper}>
        {isLoadingBlockchains && <CircularProgress sx={{ margin: 'auto' }} />}
        {errorStats && 'message' in errorStats ? errorStats.message : ''}
        {shouldRenderBlockchains &&
          dataStats?.map(blockchain => (
            <BlockChain
              isFetchingStats={isFetchingStats || isLoadingStats}
              periodCountValue={requestPeriod.value}
              periodUnit={requestPeriod.unit}
              blockchain={blockchain}
              key={uid(blockchain)}
            />
          ))}
      </Box>
    </Card>
  );
}
