import { useMemo } from 'react';
import { Box, Tooltip } from '@mui/material';

import { Colors, Report } from '../../../../../../../types';
import { t } from '../../../../../../../utils';
import { usePointStyles } from './usePointStyles';
import { allowPointGrow, getWidth } from './utils';

type PointProps = {
  color: Colors;
  reports: Report[];
  fullLength: number;
  hasSuccessItems: boolean;
};

export function Point({
  color,
  reports,
  fullLength,
  hasSuccessItems,
}: PointProps) {
  const { classes, cx } = usePointStyles();

  const getStyles = (color: Colors) => {
    switch (color) {
      case Colors.GREEN:
        return classes.green;
      case Colors.GREY:
        return classes.grey;
      case Colors.ORANGE:
        return classes.orange;
      case Colors.RED:
        return classes.red;
      default:
        return null;
    }
  };

  const title = useMemo(() => {
    if (color === Colors.GREEN) return t('main.point.healthy');
    if (color === Colors.GREY) return t('main.point.no-data');

    return (
      <Box className={classes.tooltipContent}>
        {reports.map(reportItem => {
          const dateString = reportItem.date.toLocaleDateString();

          return (
            <span>
              {dateString}: {reportItem.message || 'No data'}
            </span>
          );
        })}
      </Box>
    );
  }, [classes, color, reports]);

  const shouldGrow = allowPointGrow(color) || !hasSuccessItems;

  return (
    <Tooltip title={title}>
      <Box
        style={{
          minWidth: getWidth(color, reports.length, fullLength),
          flexGrow: shouldGrow ? 1 : 0,
        }}
        className={cx(classes.component, getStyles(color))}
      />
    </Tooltip>
  );
}
