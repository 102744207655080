export const POINT_WIDTH = 10;

export const VISIBLE_ITEMS_IDS = [
  'allora_testnet',
  'arbitrum',
  'avalanche',
  'base',
  'blast',
  'bsc',
  'eth',
  'eth_holesky_beacon',
  'optimism',
  'polygon',
];
