import { makeStyles } from 'tss-react/mui';

import { HEADER_HEIGHT, SIDEBAR_WIDTH } from '../../modules/themes';

export const useHeaderStyles = makeStyles()(theme => ({
  appBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    height: HEADER_HEIGHT,
    backgroundColor: 'transparent',
    transition: '0.3s ease all',
  },
  appBarScrolled: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: '100%',
    '&&': {
      maxWidth: 'none',
      paddingLeft: 0,
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(3),
      },
    },
  },
  logoWrap: {
    minWidth: SIDEBAR_WIDTH,
    height: '100%',
    padding: theme.spacing(0, 4),
    display: 'flex',
    gap: theme.spacing(5),
    fontSize: 20,
    letterSpacing: '-0.04em',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
}));
