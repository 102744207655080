import React, { JSX } from 'react';
import { Route } from 'react-router-dom';

import { LayoutDefault } from '../../components/LayoutDefault';
import { t } from '../utils';
import { Dashboard } from './screens/Dashboard';

export function getDashboardRoutes(): JSX.Element {
  return (
    <Route
      path="/"
      element={
        <LayoutDefault title={t('page-title.dashboard')}>
          <Dashboard />
        </LayoutDefault>
      }
    />
  );
}
