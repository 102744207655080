import { format } from 'date-fns';

import {
  Colors,
  Report,
  ServiceStatus,
  SUCCESS_COLORS,
} from '../../../../../../../types';
import { t } from '../../../../../../../utils';
import { POINT_WIDTH } from '../../../../constants';

const getMessage = (status?: ServiceStatus) => {
  if (status === ServiceStatus.HEALTHY) return t('main.point.healthy');
  if (status === ServiceStatus.OUTAGE) return t('main.point.outage');
  if (status === ServiceStatus.NOTFOUND || !status)
    return t('main.point.not-found');
};

export const getTooltipContent = (reports: Report[]) => {
  const firstRecordDate = reports[0].date;
  const lastReportRate = reports[reports.length - 1].date;

  return {
    date:
      firstRecordDate === lastReportRate
        ? firstRecordDate
        : `${format(firstRecordDate, 'mm/dd/yy')} - ${format(
            lastReportRate,
            'mm/dd/yy',
          )}`,
    message: getMessage(reports[0]?.serviceStatus),
  };
};

export const allowPointGrow = (color: Colors) => {
  return SUCCESS_COLORS.includes(color);
};

export const getWidth = (
  color: Colors,
  reportsLength: number,
  fullLength: number,
) => {
  if (SUCCESS_COLORS.includes(color)) {
    return `${(reportsLength * 100) / fullLength}%`;
  }

  return `${POINT_WIDTH * reportsLength}px`;
};
