import { TRequestPeriodId } from '../../dashboard/screens/Dashboard/useDashboard';
import { cacheTags, mainApi } from '../../service';
import { getStatusRequestEndpoint } from '../../service/const/routes';
import { noAuthApi } from '../../service/utils/Getaways';
import { MrpcResponseType, StatsResponse } from '../../types';

interface IRequestParams {
  period: TRequestPeriodId;
  blockchains: MrpcResponseType[];
}

export const { useLazyGetIncidentsQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getIncidents: build.query<StatsResponse[], IRequestParams>({
      queryFn: async ({ period, blockchains }: IRequestParams) => {
        const blockchainIds = blockchains.map(item => item.id);

        const statusRequestEndpoint = getStatusRequestEndpoint(
          period,
          blockchainIds,
        );

        const { data } = await noAuthApi.get<StatsResponse[]>(
          statusRequestEndpoint,
        );

        return { data };
      },
      providesTags: [cacheTags.providerConnection],
    }),
  }),
});
