import { makeStyles } from 'tss-react/mui';

export const useDashboardStyles = makeStyles()(theme => ({
  component: {
    '&&': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(0, 2.25)}`,
    },
  },
  wrapper: {},
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      gap: `${theme.spacing(8)}`,
    },
  },
  title: {},
  cardsWrapper: {
    marginTop: theme.spacing(7.25),
    display: 'grid',
    gap: `${theme.spacing(3)}`,
  },
  point: {
    flexGrow: '1 0 10px',
    minWidth: 8,
    height: '100%',
  },
  orange: {
    background: '#EEA941',
    borderRadius: '3px',
    margin: theme.spacing(0, 0.15),
  },
  green: {
    background: '#3AC090',
  },
  grey: {
    background: '#A7AFC0',
  },
  rounded: {
    borderRadius: '3px',
  },
  rightRounded: {
    borderRadius: '0 3px 3px 0',
  },
  leftRounded: {
    borderRadius: '3px 0 0 3px',
  },
}));
