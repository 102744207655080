import { useScrollTrigger } from '@mui/material';

const SLIDE_TIMEOUT = 500;
const HEADER_TRIGGER_THRESHOLD = 150;

export const useHeader = () => {
  const trigger = useScrollTrigger({ threshold: HEADER_TRIGGER_THRESHOLD });
  const triggerTouched = useScrollTrigger({
    threshold: 10,
    disableHysteresis: true,
  });

  return {
    trigger,
    SLIDE_TIMEOUT,
    triggerTouched,
  };
};
