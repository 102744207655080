import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const usePageNotFoundStyles = makeStyles<Theme>(theme => ({
  wrapper: {
    marginTop: theme.spacing(20),
    paddingBottom: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(15),
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(9, 10),
    minHeight: 480,
    [theme.breakpoints.down('lg')]: {
      minHeight: 460,
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 420,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 400,
      padding: theme.spacing(9, 6),
    },
  },
  title: {
    fontWeight: 700,
    lineHeight: 0.75,
    fontSize: 120,
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 96,
    },
  },
  hint: {
    marginBottom: theme.spacing(10),
  },
}));
