import { TRequestPeriodId } from '../../dashboard/screens/Dashboard/useDashboard';
import { REGION_NAME } from '../../types';
import { API_ENDPOINT } from './environment';

export const getStatusRequestEndpoint = (
  period: TRequestPeriodId,
  chains: string[],
): string => {
  const params = new URLSearchParams({
    period,
    region: REGION_NAME.WORLDWIDE,
    chains: chains.join(','),
  });

  return `${API_ENDPOINT}/statuspage/api/v1/incidents?${params.toString()}`;
};

export const GET_MRPC_DATA = 'https://rpc.ankr.com/api/v1/blockchain';
