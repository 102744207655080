import { useMemo } from 'react';

import { useAppSelector } from '../../../store/useAppSelector';
import { Locale } from '../types';

export function useLocale() {
  const localeInit = useAppSelector(({ i18n: { locale } }) => locale);

  const locale: Locale = useMemo(() => localeInit, [localeInit]);

  const languageCode: string = useMemo(
    () => localeInit.substring(0, 2),
    [localeInit],
  );

  return {
    locale,
    languageCode,
  };
}
