import { VISIBLE_ITEMS_IDS } from '../../dashboard/screens/Dashboard/constants';
import { cacheTags, mainApi } from '../../service';
import { GET_MRPC_DATA } from '../../service/const/routes';
import { noAuthApi } from '../../service/utils/Getaways';
import { MrpcResponseType } from '../../types';

export const { useGetBlockchainsQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getBlockchains: build.query<MrpcResponseType[], void>({
      queryFn: async () => {
        const { data } = await noAuthApi.get<MrpcResponseType[]>(GET_MRPC_DATA);

        return {
          data: data.filter(item => VISIBLE_ITEMS_IDS.includes(item.id)),
        };
      },
      providesTags: [cacheTags.providerConnection],
    }),
  }),
});
