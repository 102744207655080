import { makeStyles } from 'tss-react/mui';

export const useLabelStyles = makeStyles()(theme => ({
  currentStatusLabel: {
    height: '24px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: `${theme.spacing(0.5)}`,
    padding: theme.spacing(0.25, 1),
    marginLeft: 'auto',

    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
    },
  },
  currentStatusLabelGreen: {
    background: 'rgba(58, 192, 144, 1)',
  },
  currentStatusLabelOrange: {
    background: '#EEA941',
  },
  statusTitle: {
    fontSize: '14px',
    lineHeight: '140%',
    textTransform: 'uppercase',
    color: 'rgba(255, 255, 255, 1)',

    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
    },
  },
}));
