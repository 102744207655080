import { mainTheme as ankrMainTheme } from '@ankr.com/ui';
import {
  alpha,
  chipClasses,
  createTheme,
  inputAdornmentClasses,
  inputBaseClasses,
  PaletteOptions,
  svgIconClasses,
  ThemeOptions,
} from '@mui/material';

import { BREAKPOINTS, MIN_LAYOUT_WIDTH, SPACING } from './const';
import { lightPalette } from './lightPalette';

const PALETTE: PaletteOptions = lightPalette;

const defaultTheme = createTheme({
  spacing: SPACING,
  palette: PALETTE,
  breakpoints: BREAKPOINTS,
});

export const mainThemeOptions: ThemeOptions = {
  spacing: defaultTheme.spacing,
  palette: defaultTheme.palette,
  breakpoints: defaultTheme.breakpoints,
  typography: {
    h6: {
      '&&': {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '135%',
        letterSpacing: '-0.02em',
        fontFeatureSettings: "'case' on, 'cv11' on, 'calt' off",
        color: '#1F2226',
      },
    },
    h5: {
      '&&': {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '110%',
        letterSpacing: '-0.03em',
        fontFeatureSettings: "'case' on, 'cv11' on, 'calt' off",
      },
    },
    body1: {
      '&&': {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '140%',
        display: 'flex',
        alignItems: 'center',
        fontFeatureSettings: "'case' on, 'cv11' on, 'calt' off",
        color: 'rgba(130, 137, 154, 1)',
      },
    },
  },
  components: {
    ...ankrMainTheme.components,
    MuiCssBaseline: {
      ...ankrMainTheme.components?.MuiCssBaseline,
      styleOverrides: {
        'html, body, #root': {
          minWidth: MIN_LAYOUT_WIDTH,
          minHeight: '100vh',
        },
        html: {
          overflowX: 'hidden',
          overflowY: 'scroll',
          scrollBehavior: 'smooth',
        },
        body: {
          position: 'relative',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          boxSizing: 'border-box',
          fontSize: 16,
          lineHeight: 1.2,
          margin: 0,
          padding: 0,
          textRendering: 'optimizeLegibility',
          backgroundColor: defaultTheme.palette.background.default,
          color: defaultTheme.palette.text.primary,
          fontFeatureSettings: "'cv11' on, 'case' on, 'calt' off",
        },
        a: {
          color: defaultTheme.palette.grey,
          fontSize: 'inherit',
          textDecoration: 'none',
          transition: `color ${defaultTheme.transitions.duration.short}ms`,
        },
        hr: {
          borderWidth: '1px 0 0',
          borderStyle: 'solid',
          borderColor: defaultTheme.palette.divider,
        },
        'button, input, optgroup, select, textarea': {
          fontFamily: 'inherit',
          fontSize: '100%',
          lineHeight: 1.1,
          margin: 0,
        },
        '::-moz-focus-inner': { borderStyle: 'none', padding: 0 },
        'strong, b': {
          fontWeight: 700,
        },
        '::selection': {
          backgroundColor: defaultTheme.palette.primary.main,
          color: defaultTheme.palette.primary.contrastText,
          textShadow: 'none',
          WebkitTextFillColor: defaultTheme.palette.primary.contrastText,
        },
        '::-webkit-scrollbar': {
          width: 10,
          height: 10,
        },
        '::-webkit-scrollbar-corner': {
          background: 'transparent',
        },
        '::-webkit-scrollbar-track': {
          background: 'transparent',
          margin: 1,
          borderRadius: 10,
          '&:hover': {
            background: alpha(defaultTheme.palette.grey[500], 0.5),
          },
        },
        '::-webkit-scrollbar-thumb': {
          background: alpha(defaultTheme.palette.grey[700], 0.5),
          borderRadius: 10,
          transition: `background-color ${defaultTheme.transitions.duration.short}ms`,
          '&:hover': {
            background: alpha(defaultTheme.palette.grey[700], 0.7),
          },
        },
      },
    },
    MuiTypography: {
      ...ankrMainTheme.components?.MuiTypography,
      styleOverrides: {
        ...ankrMainTheme.components?.MuiTypography?.styleOverrides,
        root: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiTypography?.styleOverrides?.root,
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          fontFeatureSettings: "'cv11' on, 'case' on, 'calt' off",
        },
      },
    },
    MuiBackdrop: {
      ...ankrMainTheme.components?.MuiBackdrop,
      styleOverrides: {
        ...ankrMainTheme.components?.MuiBackdrop?.styleOverrides,
        root: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiBackdrop?.styleOverrides?.root,
          backdropFilter: 'blur(16px)',
        },
        invisible: {
          backdropFilter: 'none',
        },
      },
    },
    MuiContainer: {
      ...ankrMainTheme.components?.MuiContainer,
      styleOverrides: {
        ...ankrMainTheme.components?.MuiContainer?.styleOverrides,
        root: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiContainer?.styleOverrides?.root,
          [defaultTheme.breakpoints.only('xl')]: {
            maxWidth: 1374,
            paddingLeft: ankrMainTheme.spacing(7.5),
            paddingRight: ankrMainTheme.spacing(7.5),
          },
          [defaultTheme.breakpoints.down('xl')]: {
            maxWidth: 1060,
            paddingLeft: ankrMainTheme.spacing(7.5),
            paddingRight: ankrMainTheme.spacing(7.5),
          },
          [defaultTheme.breakpoints.down('sm')]: {
            paddingLeft: ankrMainTheme.spacing(4),
            paddingRight: ankrMainTheme.spacing(4),
          },
        },
      },
    },
    MuiCard: {
      ...ankrMainTheme.components?.MuiCard,
      styleOverrides: {
        ...ankrMainTheme.components?.MuiCard?.styleOverrides,
        root: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiCard?.styleOverrides?.root,
          [defaultTheme.breakpoints.up('md')]: {
            borderRadius: 30,
          },
        },
      },
    },
    MuiInputBase: {
      ...ankrMainTheme.components?.MuiInputBase,
      styleOverrides: {
        ...ankrMainTheme.components?.MuiInputBase?.styleOverrides,
        root: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiInputBase?.styleOverrides?.root,
          [`&.${inputBaseClasses.multiline}`]: {
            height: 'auto',
            minHeight: 48,
            paddingTop: ankrMainTheme.spacing(2),
          },
        },
      },
    },
    MuiInputAdornment: {
      ...ankrMainTheme.components?.MuiInputAdornment,
      styleOverrides: {
        ...ankrMainTheme.components?.MuiInputAdornment?.styleOverrides,
        root: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiInputAdornment?.styleOverrides?.root,
          '& > img': {
            width: 24,
            height: 24,
          },
          [`&.${inputAdornmentClasses.sizeSmall} > img`]: {
            width: 16,
            height: 16,
          },
        },
      },
    },
    MuiSelect: {
      ...ankrMainTheme.components?.MuiSelect,
      styleOverrides: {
        ...ankrMainTheme.components?.MuiSelect?.styleOverrides,
        select: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiSelect?.styleOverrides?.select,
          color: ankrMainTheme.palette.text.primary,
          fontWeight: 400,
        },
      },
    },
    MuiAutocomplete: {
      ...ankrMainTheme.components?.MuiAutocomplete,
      styleOverrides: {
        ...ankrMainTheme.components?.MuiAutocomplete?.styleOverrides,
        endAdornment: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiAutocomplete?.styleOverrides
            ?.endAdornment,
          right: ankrMainTheme.spacing(1),
          top: `calc(50% - ${ankrMainTheme.spacing(5)})`,
          [`& .${svgIconClasses.root}`]: {
            fill: ankrMainTheme.palette.grey[400],
          },
        },
        inputRoot: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiAutocomplete?.styleOverrides
            ?.inputRoot,
          [`&.${inputBaseClasses.root}`]: {
            height: 'auto',
            minHeight: 48,
            paddingTop: ankrMainTheme.spacing(0.5),
          },
        },
      },
    },
    MuiSvgIcon: {
      ...ankrMainTheme.components?.MuiSvgIcon,
      styleOverrides: {
        ...ankrMainTheme.components?.MuiSvgIcon?.styleOverrides,
        root: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...ankrMainTheme.components?.MuiSvgIcon?.styleOverrides?.root,
          [`&.${chipClasses.deleteIcon}`]: {
            fill: ankrMainTheme.palette.grey[400],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        ...ankrMainTheme.components?.MuiChip?.styleOverrides,
        root: {
          [`&.${chipClasses.sizeSmall}`]: {
            borderRadius: 8,
          },
          [`&.${chipClasses.colorPrimary}`]: {
            backgroundColor: ankrMainTheme.palette.primary.light,
            color: ankrMainTheme.palette.primary.main,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontFamily: 'Inter, sans-serif',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '150%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontFeatureSettings: "'case' on, 'cv11' on, 'calt' off",
          color: '#FFFFFF',
          background: '#356DF3',
          borderRadius: '16px',
          transition: '0.3s ease all',
          width: '100%',
          height: 48,
          boxShadow: 'none',

          '&:hover': {
            background: '#2860de',
          },
        },
      },
    },
  },
};
