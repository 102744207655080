import React from 'react';
import { Box, Typography } from '@mui/material';

import { GroupedRecord } from '../../../../../../../types';
import { useLabel } from './useLabel';
import { useLabelStyles } from './useLabelStyles';

type LabelProps = {
  lastItem: GroupedRecord;
};

export function Label({ lastItem }: LabelProps) {
  const { classes, cx } = useLabelStyles();
  const { getLabelData } = useLabel();
  const labelData = getLabelData(lastItem);

  if (!labelData) return null;

  return (
    <Box className={cx(classes.currentStatusLabel, labelData.className || '')}>
      <img width={20} height={20} src={labelData.icon} alt="" />
      <Typography variant="body1" className={classes.statusTitle}>
        {labelData.title || ''}
      </Typography>
    </Box>
  );
}
