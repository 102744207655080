import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { AXIOS_DEFAULT_CONFIG } from '../const/AXIOS_DEFAULT_CONFIG';

export class GatewayNoAuth {
  public readonly api: AxiosInstance;

  constructor(private readonly config?: AxiosRequestConfig) {
    this.api = axios.create({
      ...this.config,
      ...AXIOS_DEFAULT_CONFIG,
    });
  }
}
