import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { cacheTags } from './const/cacheTags';
import { API_ENDPOINT } from './const/environment';

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
  }),
  endpoints: () => ({}),
  tagTypes: Object.values(cacheTags),
});
