import { useEffect } from 'react';

import { t } from '../utils';

interface IUsePageTitleProps {
  projectName: string;
  pageTitle?: string;
}

export const usePageTitle = ({
  projectName,
  pageTitle,
}: IUsePageTitleProps) => {
  useEffect(() => {
    let titleString = pageTitle ? t('page-title-with-separator') : '';
    titleString += projectName;
    document.title = titleString;
  }, [projectName, pageTitle]);
};
