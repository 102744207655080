import { subDays, subHours } from 'date-fns';

import { TRequestPeriodUnit } from '../dashboard/screens/Dashboard/useDashboard';
import {
  Colors,
  GroupedRecord,
  REGION_NAME,
  Report,
  ServiceStatus,
  StatsDay,
  StatsRegionData,
  StatsRegions,
  SUCCESS_COLORS,
  SUCCESS_STATUSES,
} from '../types';

const getStatusColor = (report?: Report): Colors => {
  switch (report?.serviceStatus) {
    case ServiceStatus.HEALTHY:
      return Colors.GREEN;
    case ServiceStatus.OUTAGE:
      return Colors.ORANGE;
    case ServiceStatus.DEGRADED:
      return Colors.RED;
    case ServiceStatus.NOTFOUND:
    default:
      return Colors.GREY;
  }
};

export const groupReportsByColor = (reports: Report[]): GroupedRecord[] => {
  if (!reports.length) return [];

  let currentGroup: GroupedRecord = {
    color: getStatusColor(reports[0]),
    records: [reports[0]],
  };

  const grouped: GroupedRecord[] = [currentGroup];

  for (let i = 1; i < reports.length; i++) {
    const currentReport = reports[i];

    const currentReportColor = getStatusColor(currentReport);

    if (
      currentReportColor === currentGroup.color &&
      SUCCESS_COLORS.includes(currentReportColor)
    ) {
      currentGroup.records.push(currentReport);
    } else {
      currentGroup = { color: currentReportColor, records: [currentReport] };

      grouped.push(currentGroup);
    }
  }

  return grouped;
};

export const getStatsByDay = (
  totalDays: number,
  regionStats?: StatsRegions,
): GroupedRecord[] => {
  const worldWideStats = regionStats?.[REGION_NAME.WORLDWIDE];
  if (!worldWideStats) return [];

  const reports: Report[] = [];
  const today = new Date();

  for (let i = 0; i < totalDays; i++) {
    const date = subDays(today, totalDays - i - 1);
    const dayStatsArray = filterStatsByDate(worldWideStats, date, 'days');
    const itemStatus = getItemStatus(dayStatsArray);

    reports.push({
      date,
      serviceStatus: itemStatus.status,
      message: itemStatus.message,
    });
  }

  return groupReportsByColor(reports);
};

export const getStatsByHour = (
  totalHours: number,
  regionStats?: StatsRegions,
): GroupedRecord[] => {
  const worldWideStats = regionStats?.[REGION_NAME.WORLDWIDE];
  if (!worldWideStats) return [];

  const reports: Report[] = [];
  const date = new Date();
  const today = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
  );

  for (let i = 0; i < totalHours; i++) {
    const date = subHours(today, totalHours - i - 1);
    const hourStatsArray = filterStatsByDate(worldWideStats, date, 'hours');
    const itemStatus = getItemStatus(hourStatsArray);

    reports.push({
      date,
      serviceStatus: itemStatus.status,
      message: itemStatus.message,
    });
  }

  return groupReportsByColor(reports);
};

const filterStatsByDate = (
  worldWideStats: StatsRegionData,
  date: Date,
  type: TRequestPeriodUnit,
) => {
  return worldWideStats?.days?.filter(({ year, month, day, hour }) => {
    const newDate = new Date(`${year}/${month}/${day}`);

    if (type === 'hours') {
      return (
        newDate.getDay() === date.getDay() &&
        Number(hour ?? 0) === date.getHours()
      );
    }

    return newDate.toDateString() === date.toDateString();
  });
};

const getItemStatus = (
  dayStatsArray?: StatsDay[],
): { status: ServiceStatus; message?: string } => {
  const badStatus = dayStatsArray?.find(
    dayStats => SUCCESS_STATUSES.indexOf(dayStats.status) === -1,
  );

  return {
    status:
      badStatus?.status ?? dayStatsArray?.[0]?.status ?? ServiceStatus.NOTFOUND,
    message: badStatus?.description,
  };
};

export const countUptimePercent = (data: GroupedRecord[]) => {
  const allRecords = data.reduce((total, obj) => total + obj.records.length, 0);
  const greenRecords = data
    .filter(item => SUCCESS_COLORS.includes(item.color))
    .reduce((total, obj) => total + obj.records.length, 0);

  return ((greenRecords / allRecords) * 100).toFixed(2);
};
