import { makeStyles } from 'tss-react/mui';

export const useSubscribeStyles = makeStyles()(theme => ({
  wrapper: {
    background: '#FFFFFF',
    borderRadius: '16px',
    padding: theme.spacing(7.5, 8),
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(3)}`,
    marginBottom: theme.spacing(2),
  },
  title: {},
  content: {
    display: 'grid',
    gap: theme.spacing(5),
  },
}));
