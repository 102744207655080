export const MIN_LAYOUT_WIDTH = 375;
export const HEADER_HEIGHT = 72;

export const SIDEBAR_WIDTH = 220;

export const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 840,
    md: 1080,
    lg: 1280,
    xl: 1440,
  },
};

export const SPACING = 4;
