import { Colors, GroupedRecord } from '../../../../../../../types';
import { t } from '../../../../../../../utils';
import operational from './assets/operational.svg';
import outage from './assets/outage.svg';
import { useLabelStyles } from './useLabelStyles';

export const useLabel = () => {
  const { classes } = useLabelStyles();

  const getLabelData = (lastItem: GroupedRecord) => {
    switch (lastItem.color) {
      case Colors.RED:
        return null;
      case Colors.ORANGE:
        return {
          title: t('main.operational.partial'),
          className: classes.currentStatusLabelOrange,
          icon: outage,
        };
      case Colors.GREY:
        return null;
      case Colors.GREEN:
        return {
          title: t('main.operational.operational'),
          className: classes.currentStatusLabelGreen,
          icon: operational,
        };
      default:
        return null;
    }
  };

  return {
    getLabelData,
  };
};
