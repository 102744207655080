import { makeStyles } from 'tss-react/mui';

export const useSidebarStyles = makeStyles()(theme => ({
  component: {
    maxWidth: 400,
    width: '100%',
    display: 'grid',
    gap: `${theme.spacing(4)}`,
    alignContent: 'start',
  },
  wrapper: {},
  title: {},
}));
