import allora from './assets/allora.svg';
import aptos from './assets/aptos.svg';
import arbitrum from './assets/arbitrum.svg';
import arbitrumnova from './assets/arbitrumnova.svg';
import avail from './assets/avail.svg';
import avalanche from './assets/avalanche.svg';
import b2 from './assets/b2.svg';
import base from './assets/base.png';
import berachain from './assets/berachain.svg';
import bitlayer from './assets/bitlayer.svg';
import blast from './assets/blast.svg';
import bsc from './assets/bsc.svg';
import btc from './assets/btc.svg';
import bttc from './assets/bttc.svg';
import celo from './assets/celo.svg';
import chiliz from './assets/chiliz.svg';
import core from './assets/core.svg';
import eth from './assets/eth.svg';
import ethDark from './assets/ethDark.svg';
import fantom from './assets/fantom.svg';
import filecoin from './assets/filecoin.svg';
import flare from './assets/flare.svg';
import gnosis from './assets/gnosis.svg';
import harmony from './assets/harmony.svg';
import heco from './assets/heco.svg';
import horizen from './assets/horizen.svg';
import iotex from './assets/iotex.svg';
import kava from './assets/kava.svg';
import kinto from './assets/kinto.svg';
import klaytn from './assets/klaytn.svg';
import kusama from './assets/kusama.svg';
import linea from './assets/linea.svg';
import mantle from './assets/mantle.svg';
import metis from './assets/metis.svg';
import moonbeam from './assets/moonbeam.svg';
import near from './assets/near.svg';
import nervos from './assets/nervos.svg';
import optimism from './assets/optimism.svg';
import polkadot from './assets/polkadot.svg';
import polygon from './assets/polygon.svg';
// eslint-disable-next-line camelcase
import polygon_zkevm from './assets/polygon_zkevm.svg';
import rollux from './assets/rollux.svg';
import scroll from './assets/scroll.svg';
import scrt from './assets/scrt.svg';
import sei from './assets/sei.svg';
import solana from './assets/solana.svg';
import stellar from './assets/stellar.svg';
import sui from './assets/sui.svg';
import syscoin from './assets/syscoin.svg';
import taiko from './assets/taiko.svg';
import tenet from './assets/tenet.svg';
import tron from './assets/tron.svg';
import xai from './assets/xai.png';
import xdc from './assets/xdc.svg';
import xlayer from './assets/xlayer.svg';
// eslint-disable-next-line camelcase
import zksync_era from './assets/zksync_era.svg';

export const chainsIcons = [
  { title: 'polygon', link: polygon },
  { title: 'eth', link: eth },
  { title: 'eth_holesky_beacon', link: ethDark },
  { title: 'bsc', link: bsc },
  { title: 'xai', link: xai },
  {
    title: 'fantom',
    link: fantom,
  },
  {
    title: 'allora',
    link: allora,
  },
  { title: 'arbitrum', link: arbitrum },
  { title: 'blast', link: blast },
  {
    title: 'avalanche',
    link: avalanche,
  },
  { title: 'solana', link: solana },
  { title: 'optimism', link: optimism },
  {
    title: 'gnosis',
    link: gnosis,
  },
  { title: 'berachain', link: berachain },
  { title: 'near', link: near },
  {
    title: 'base',
    link: base,
  },
  { title: 'tron', link: tron },
  { title: 'scroll', link: scroll },
  {
    title: 'celo',
    link: celo,
  },
  { title: 'zksync_era', link: zksync_era },
  { title: 'sui', link: sui },
  {
    title: 'core',
    link: core,
  },
  { title: 'mantle', link: mantle },
  { title: 'klaytn', link: klaytn },
  {
    title: 'sei',
    link: sei,
  },
  { title: 'iotex', link: iotex },
  { title: 'flare', link: flare },
  {
    title: 'polygon_zkevm',
    link: polygon_zkevm,
  },
  { title: 'moonbeam', link: moonbeam },
  { title: 'filecoin', link: filecoin },
  {
    title: 'harmony',
    link: harmony,
  },
  { title: 'kava', link: kava },
  { title: 'aptos', link: aptos },
  { title: 'linea', link: linea },
  {
    title: 'xdc',
    link: xdc,
  },
  { title: 'bitlayer', link: bitlayer },
  { title: 'chiliz', link: chiliz },
  {
    title: 'horizen',
    link: horizen,
  },
  { title: 'arbitrumnova', link: arbitrumnova },
  { title: 'taiko', link: taiko },
  {
    title: 'metis',
    link: metis,
  },
  { title: 'xlayer', link: xlayer },
  { title: 'syscoin', link: syscoin },
  {
    title: 'bttc',
    link: bttc,
  },
  { title: 'tenet', link: tenet },
  { title: 'scrt', link: scrt },
  { title: 'b2', link: b2 },
  {
    title: 'polkadot',
    link: polkadot,
  },
  { title: 'rollux', link: rollux },
  { title: 'heco', link: heco },
  {
    title: 'nervos',
    link: nervos,
  },
  { title: 'stellar', link: stellar },
  { title: 'btc', link: btc },
  {
    title: 'kinto',
    link: kinto,
  },
  { title: 'kusama', link: kusama },
  { title: 'avail', link: avail },
];
