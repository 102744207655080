import React from 'react';
import { uid } from 'react-uid';
import { Box, Skeleton, Typography } from '@mui/material';

import { ChainState, SUCCESS_COLORS } from '../../../../../types';
import { t } from '../../../../../utils';
import { TRequestPeriodUnit } from '../../useDashboard';
import { Label } from './components/Label/Label';
import { Point } from './components/Point/Point';
import { useBlockChainStyles } from './useBlockChainStyles';

type BlockChainProps = {
  isFetchingStats: boolean;
  blockchain: ChainState;
  periodCountValue: number;
  periodUnit: TRequestPeriodUnit;
};

export function BlockChain({
  blockchain,
  periodCountValue,
  periodUnit,
  isFetchingStats,
}: BlockChainProps) {
  const { classes } = useBlockChainStyles();

  const { icon, name, chartData, uptime } = blockchain;

  const hasSuccessItems = chartData.some(({ color }) =>
    SUCCESS_COLORS.includes(color),
  );

  return (
    <Box className={classes.cardWrapper}>
      <Box className={classes.titleWrapper}>
        <Box className={classes.logoWrapper}>
          <img className={classes.logo} src={icon} alt="" />
          <Typography
            variant="h6"
            variantMapping={{ h6: 'h3' }}
            className={classes.networkTitle}
          >
            {name}
          </Typography>
        </Box>
        <Box className={classes.labelWrapper}>
          {isFetchingStats && (
            <Skeleton variant="rounded" width={215} height={24} />
          )}
          {!isFetchingStats && chartData && chartData.length > 0 && (
            <>
              <Label lastItem={chartData[chartData.length - 1]} />
              <Typography
                variant="h6"
                variantMapping={{ h6: 'span' }}
                className={classes.percentage}
              >
                {uptime}
                {t('percent')}
              </Typography>
            </>
          )}
        </Box>
      </Box>

      <Box className={classes.healthScaleWrapper}>
        {isFetchingStats && (
          <Skeleton variant="rectangular" width="100%" height="12" />
        )}
        {!isFetchingStats &&
          chartData.map(record => (
            <Point
              key={uid(record)}
              color={record.color}
              reports={record.records}
              fullLength={periodCountValue}
              hasSuccessItems={hasSuccessItems}
            />
          ))}
      </Box>

      <Box className={classes.scaleUnderLabels}>
        <Typography variant="body2" className={classes.label}>
          {t('main.card.days-ago.period', {
            count: periodCountValue,
            unit: periodUnit,
          })}
        </Typography>
        <Typography variant="body2" className={classes.label}>
          {periodUnit === 'hours'
            ? t('main.card.days-ago.now')
            : t('main.card.days-ago.today')}
        </Typography>
      </Box>
    </Box>
  );
}
