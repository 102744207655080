import { makeStyles } from 'tss-react/mui';

export const usePointStyles = makeStyles()(theme => ({
  component: {
    borderRadius: '3px',
    height: 12,
    transition: '0.3s ease all',
    cursor: 'pointer',
  },
  orange: {
    background: '#EEA941',

    '&:hover': {
      background: '#c98f39',
    },
  },
  green: {
    background: '#3AC090',

    '&:hover': {
      background: '#389f7a',
    },
  },
  grey: {
    background: '#A7AFC0',

    '&:hover': {
      background: '#82899A',
    },
  },
  red: {
    background: '#D22C54',

    '&:hover': {
      background: '#a62544',
    },
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}`,
  },
  title: {},
}));
